/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var windowWidth = 0;

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				windowWidth = $(window).width();

				// Page Load Transition
				$(function(){
					//Change the opacity to 1
					$(".wrap").css('opacity','1');
					$(".wrap").css('margin-top','0rem');
				});

				// mobile menu toggle
				var $menuButton = $('.menu-btn');
				var $menu = $('.nav');

				$menuButton.on('click', function(){
					$menu.slideToggle();
					$menuButton.toggleClass('active');
					$('#nav-icon').toggleClass('open');
					// $('.menu-item').addClass('active');
				});

				// Touch Device Detection
				var isTouchDevice = 'ontouchstart' in document.documentElement;
				if( isTouchDevice ) {
					$('body').removeClass('no-touch');
				}

				$('.tabs__header').on('click', function () {
					$('.tabs__buttons').toggleClass('active');
				});

				$('.tabs__buttons .button').on('click', function () {
					$('.tabs__buttons').removeClass('active');
				});

				// column heights 


				function sizeColumns() {
					$('.flexible-content__section--mixed').each(function(){
						var textColumn = $(this).find('.flexible-content__column--text');
						var textColumnHeight = textColumn.outerHeight();
						var image = $(this).find('.flexible-content__column--image img');
						var imageHeight = image.height();
						// console.log('textColumnHeight= ' + textColumnHeight);
						// console.log('imageHeight= ' . imageHeight);

						image.height(textColumnHeight);

					});
				}
				
				$('body').imagesLoaded( function() {
					if (windowWidth >= 992 ){
						sizeColumns();
					}
				});
				
				$(window).on('resize', function(){
					windowWidth = $(window).width();
					if (windowWidth >= 992 ){
						sizeColumns();
					}
				});

				// video player

				$('.play-controls').click(function(e){
					e.preventDefault();

					if( $(this).hasClass('paused') ) {
						$('video').each(function() {
					    $(this).get(0).pause();
						});
						$('.play-controls.playing').removeClass('playing').addClass('paused');
						$('.play-controls').find('.fa').removeClass('fa-pause-circle').addClass('fa-play-circle');

						$(this).siblings('video').get(0).play();
						$(this).removeClass('paused').addClass('playing');
						$(this).find('.fa').removeClass('fa-play-circle').addClass('fa-pause-circle');
					} else {
						$(this).siblings('video').get(0).pause();
						$(this).removeClass('playing').addClass('paused');
						$(this).find('.fa').removeClass('fa-pause-circle').addClass('fa-play-circle');
					}
					
				});

				reframe('iframe');


        if(windowWidth >= 768) {

          // Header parallax
          var headerParallax = function(){
            var scroll = $(window).scrollTop();
            $('.page-header').css('transform', 'translateY(' + scroll/2 + 'px)');
            $('.page-header__content').css('transform', 'translateY(calc(-50% - ' + scroll/4 + 'px))');
          };
        
          var throttledHeaderParallax = _.throttle(headerParallax, 10);

          // $(window).scroll(throttledHeaderParallax);

        }



			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page

				// Home page Hero slider
				$(".hero__slider").owlCarousel({
					items: 1,
					loop: true,
					autoplay: false,
					autoplaySpeed: 1000,
					dotsSpeed: 1000,
					responsiveRefreshRate: 15,
					onInitialized: function() {
				      $(".owl-item.active video")[0].play();
				  },
				  onTranslated: function() {
				  	$(".owl-item video").each(function( index ) {
				  		$(this)[0].pause();
				  	});

				  	if ($(".owl-item.active video").length) {
					  	$(".owl-item.active video")[0].play();
					  }

				    // if ($(".owl-item.active video", this.$element).length) {
				    //   $(".owl-item.active video", this.$element)[0].play();
				    //   owl.trigger('stop.owl.autoplay')
				    //   $(".owl-item.active video", this.$element).on('ended', function() {
				    //     owl.trigger('play.owl.autoplay')
				    //   });
				    // }
				  }
				});

        $(".featured-case-studies__bottom").owlCarousel({
          items: 3,
          loop: true,
          autoplay: true,
          autoplaySpeed: 1000,
          dotsSpeed: 1000,
          responsive:{
            0:{
              items:1
            },
            768: {
              items: 3
            }
          }
        });

				var windowWidth = $(window).width();

				if(windowWidth >= 768) {

					// Home page parallax
					var heroParallax = function(){
						var scroll = $(window).scrollTop();
						$('.hero__slider').css('transform', 'translateY(' + scroll/2 + 'px)');
						$('.slide__content').css('transform', 'translateY(-' + scroll/4 + 'px)');
					};
				
					var throttledHeroParallax = _.throttle(heroParallax, 10);

					$(window).scroll(throttledHeroParallax);

				}

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'post_type_archive_case_study': {
			init: function() {
				// JavaScript to be fired on the work page

				// Work page Grid
				var $grid = $('.case-studies').isotope({
					itemSelector: '.case-study',
					layoutMode: 'packery',
				});

				// imagesLoaded scale items and layout isotope
				$grid.imagesLoaded().progress( function() {
					// $('.case-study--large').height($('.case-study--medium').height());
				  // $grid.isotope('layout');
				});

				// isotope filters
				$('.tabs').on( 'click', 'button', function() {
					$('.tabs .button.active').removeClass('active');
					$(this).addClass('active');

				  var filterValue = $(this).attr('data-filter');
				  $grid.isotope({ filter: filterValue });
				});

				// case study resizing
				$( window ).resize(function() {
				  $('.case-study--large').height($('.case-study--medium').height());
				});
			}
		},
		'single_case_study': {
			init: function() {
				$(".owl-carousel").imagesLoaded( function() {
					$(".owl-carousel").owlCarousel({
						items: 1,
						loop: true,
						autoplay: false,
						autoplaySpeed: 1000,
						responsiveRefreshRate: 15,
						autoHeight: true,
						onTranslated: function() {
								var $video = $('.owl-item.active').find('video');
								if($video.length) {
					        $video.get(0).play();
					      }
				    }
					});
				});

				var removeListStyle = function() {
					var firstPosition = 0;

					$('.single-case-study__product').each(function(index){
						var currentItem = $(this);
						var currenItemPosition = currentItem.position();

						if (index === 0) {
							firstPosition = currentItem.position();
						}
						
						if (currenItemPosition.left === firstPosition.left) {
							currentItem.addClass('first-of-line');
						} else {
							currentItem.removeClass('first-of-line');
						}
					});
				};
				
				removeListStyle();

				$( window ).resize(function() {
					removeListStyle();
				});

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
